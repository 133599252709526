.tooltip-rader {
  border: 1px solid black;
  position: absolute;
  text-align: center;
  width: auto;
  height: auto;
  padding: 5px;
  font: 12px;
  background-color: white;
  visibility: hidden;
}

.tooltip-regionheat {
  border: 1px solid black;
  position: absolute;
  text-align: center;
  width: auto;
  height: auto;
  padding: 5px;
  font: 12px;
  background: white;
  visibility: hidden;
}

.tooltip-countryheat {
  border: 1px solid black;
  position: absolute;
  text-align: center;
  width: auto;
  height: auto;
  padding: 5px;
  font: 12px;
  background: white;
  visibility: hidden;
}

.tooltip-world {
  border: 1px solid black;
  position: absolute;
  text-align: center;
  width: auto;
  height: auto;
  padding: 5px;
  font: 12px;
  background-color: white;
  visibility: hidden;
}

.tooltip-bar {
  border: 1px solid black;
  position: absolute;
  text-align: center;
  width: auto;
  height: auto;
  padding: 5px;
  font: 12px;
  background: white;
  visibility: hidden;
}

.tooltip-swarm {
  border: 1px solid black;
  position: absolute;
  text-align: center;
  width: auto;
  height: auto;
  padding: 5px;
  font: 12px;
  background: white;
  visibility: hidden;
}

.tooltip-questionMark {
  border: 1px solid lightgray;
  position: absolute;
  right: 5.5%;
  text-align: center;
  width: auto;
  height: auto;
  padding: 5px;
  font: 12px;
  background: white;
  visibility: hidden;
}

.tooltip-aboutFeature {
  border: 1px solid black;
  position: absolute;
  text-align: center;
  width: auto;
  height: auto;
  padding: 5px;
  font: 12px;
  background-color: white;
  visibility: hidden;
}