.my-section {
  padding: 0.5rem 0.25rem 0.25rem 0.5rem;
}
.test {
  width: 100%;
  max-width: 50vw;
}
.ranking {
  width: 50%;
}

.songs {
  width: calc(50% - 8px);
}

.heightMax {
  height: 100%;
}
.colorLegend {
  width: -webkit-fill-available;
  max-width: 300px;
}

@media (max-width: 768px) {
  .test {
    width: 100%;
    max-width: 100vw;
  }
  .ranking {
    width: 100%;
  }
  .tile .grid {
    display: grid;
  }
  .songs {
    width: 100%;
  }
}

.ranking-scroll {
  max-height: 450px;
  overflow: scroll;
}

.swmplt-scroll {
  max-height: 250px;
  overflow: scroll;
}

.questionmark {
  cursor: default;
  text-align: center;
  background-color: lightgray;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 18px;
  line-height: 25px;
}

.axisBlack line{
  stroke: black;
}

.axisBlack path{
  stroke: black;
}

.axisBlack text{
  fill: black;
}

label {
  white-space: nowrap;
}